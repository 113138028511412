var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "swiper" },
        [
          _c(
            "mt-swipe",
            { staticClass: "spacing", attrs: { auto: 4000 } },
            _vm._l(_vm.swipeList, function(img, index) {
              return _c("mt-swipe-item", { key: index }, [
                _c("div", [_c("img", { attrs: { src: img.slideshowPath } })])
              ])
            }),
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "iconList" }, [
        _c("ul", [
          _c("li", { on: { click: _vm.repair } }, [
            _c("img", {
              attrs: { src: require("../../assets/img/enterprise/repair.png") }
            }),
            _c("p", [_vm._v("设备报修")])
          ]),
          _c("li", { on: { click: _vm.cost } }, [
            _c("img", {
              attrs: { src: require("../../assets/img/enterprise/cost.png") }
            }),
            _c("p", [_vm._v("费用查看")])
          ]),
          _c("li", { on: { click: _vm.demand } }, [
            _c("img", {
              attrs: { src: require("../../assets/img/enterprise/demand.png") }
            }),
            _c("p", [_vm._v("需求大厅")])
          ]),
          _c("li", { on: { click: _vm.setting } }, [
            _c("img", {
              attrs: { src: require("../../assets/img/enterprise/setting.png") }
            }),
            _c("p", [_vm._v("我的")])
          ])
        ])
      ]),
      _c("Announcement")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }